(function(sync){
    Backbone.Collection.prototype.sync = function(method, model, options) {
        return sync(method, model, $.extend(true, {
            headers: EG.commonClientHeaders
        }, options));
    };
})(Backbone.Collection.prototype.sync);

(function(sync){
    Backbone.Model.prototype.sync = function(method, model, options) {
        return sync(method, model, $.extend(true, {
            headers: EG.commonClientHeaders
        }, options));
    };
})(Backbone.Model.prototype.sync);
